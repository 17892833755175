<template>
  <b-row>
    <b-col lg="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col lg="7">
            <h4 id="traffic" class="card-title mb-0">Entregas de Pedidos</h4>
            <div class="small text-muted">Administrar todas las formas de entregas de un pedidos</div>
          </b-col>
        </b-row>
      </b-card>

      <b-card no-body>
        <b-tabs pills card>
          <b-tab title="Retiro en Local" active>
            <b-card-text>              
              <b-row class="mb-3">                              
                <b-col lg="12">
                  <b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Local de Entregas">
                    <i class="fa fa-plus"></i> Agregar
                  </b-button>
                </b-col>          
              </b-row>
              <b-row>
                <b-col>
                  <b-table class="mb-0"
                          responsive="sm"
                          head-variant="dark"
                          :hover="true"
                          :small="true"
                          :fixed="false"
                          :items="table.items"
                          :fields="table.fields"
                          v-if="table.items.length">

                      <template v-slot:cell(id)="data">
                        <b>{{ data.item.id }}</b>
                      </template>
                    
                      <template v-slot:cell(name)="data">
                        {{ data.item.name }}
                      </template>

                      <template v-slot:cell(code)="data">
                        {{ data.item.description }}
                      </template>                                            

                      <template v-slot:cell(f_action)="data">
                        <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                          <b-dropdown-item @click="edit(data.item)">
                            <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                          </b-dropdown-item>
                          <b-dropdown-item @click="remove(data.item)">
                            <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                          </b-dropdown-item>
                        </b-dropdown>
                      </template>
                  </b-table>
                  <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
                </b-col>
              </b-row>              
            </b-card-text>
          </b-tab>
          
          <b-tab title="Tarifario Simple">
            <b-card-text>              
              <b-row class="mb-3">                              
                <b-col lg="12">
                  <b-button @click="addTariffSimple()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Tarifa">
                    <i class="fa fa-plus"></i> Agregar
                  </b-button>
                </b-col>          
              </b-row>
              <b-row>
                <b-col>
                  <b-table class="mb-0"
                          responsive="sm"
                          head-variant="dark"
                          :hover="true"
                          :small="true"
                          :fixed="false"
                          :items="tableTariffSimple.items"
                          :fields="tableTariffSimple.fields"
                          v-if="tableTariffSimple.items.length">

                      <template v-slot:cell(id)="data">
                        <b>{{ data.item.id }}</b>
                      </template>
                    
                      <template v-slot:cell(name)="data">
                        {{ data.item.name }}
                      </template>

                      <template v-slot:cell(country)="data">
                        <div v-if="data.item.country">
                          <b>{{ data.item.country }}</b>
                        </div>
                        <div v-else>                          
                          <b-badge variant="secondary">DISPONIBLE EN TODO LOS PAISES</b-badge>
                        </div>
                      </template>                      

                      <template v-slot:cell(price)="data">
                        <div v-if="data.item.price>0">
                          {{Intl.NumberFormat('es-AR',{style:'currency',currency:'ARS'}).format(data.item.price)}}
                        </div>
                        <div v-else>
                          <div v-if="data.item.legend">
                            {{data.item.legend}}
                          </div>
                          <div v-else>
                            {{Intl.NumberFormat('es-AR',{style:'currency',currency:'ARS'}).format(data.item.price)}}
                          </div>
                        </div>
                      </template>                

                      <template v-slot:cell(f_action)="data">
                        <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                          <b-dropdown-item @click="editTariffSimple(data.item)">
                            <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                          </b-dropdown-item>
                          <b-dropdown-item @click="removeTariffSimple(data.item)">
                            <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                          </b-dropdown-item>
                        </b-dropdown>
                      </template>
                  </b-table>
                  <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
                </b-col>
              </b-row>              
            </b-card-text>
          </b-tab>

          <b-tab title="Tarifario Condicional">
            <b-card-text>   
              <b-row class="mb-3">                              
                <b-col lg="12">
                  <b-button @click="addTariffConditional()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Tarifa">
                    <i class="fa fa-plus"></i> Agregar
                  </b-button>
                </b-col>          
              </b-row>              
              <b-row>
                <b-col>
                  <b-table class="mb-0"
                          responsive="sm"
                          head-variant="dark"
                          :hover="true"
                          :small="true"
                          :fixed="false"
                          :items="tableTariffConditional.items"
                          :fields="tableTariffConditional.fields"
                          v-if="tableTariffConditional.items.length">

                      <template v-slot:cell(id)="data">
                        <b>{{ data.item.id }}</b>
                      </template>
                    
                      <template v-slot:cell(name)="data">
                        {{ data.item.name }}
                      </template> 

                      <template v-slot:cell(limit)="data">                        
                        <div v-if="data.item.province_or_cp == 'province'">
                          <div v-if="data.item.provinces">                          
                            <span :id="'provinces-delivery-'+data.item.id">
                              {{ JSON.parse(data.item.provinces)[0] }} ...
                            </span>
                            <b-popover :target="'provinces-delivery-'+data.item.id" triggers="hover" placement="top">
                              <template #title>Provincias</template>
                              <ul>                              
                                <li v-for="(province, index) in JSON.parse(data.item.provinces)" :key="index+'-'+province">
                                  {{province}}
                                </li>
                              </ul>
                            </b-popover>                          
                          </div>
                        </div>
                        <div v-if="data.item.province_or_cp == 'cp'">
                          <b title="Código Postal">CP:</b> {{data.item.postal_code}}
                        </div>
                      </template> 

                      <template v-slot:cell(at_home)="data">
                        <div v-if="data.item.at_home">
                          A Domicilio
                        </div>
                        <div v-else>
                          En Sucursal
                        </div>                        
                      </template> 

                      <template v-slot:cell(weight)="data">
                        {{ data.item.weight }} KG.
                      </template> 

                      <template v-slot:cell(measures)="data">
                        <b>{{ data.item.long }} cm.</b> Largo x <b>{{ data.item.height }} cm.</b> Alto x <b>{{ data.item.depth }} cm.</b> Profundidad
                      </template> 
                      
                      <template v-slot:cell(country)="data">
                        <div v-if="data.item.country">
                          <b>{{ data.item.country }}</b>
                        </div>
                        <div v-else>                          
                          <b-badge variant="secondary">DISPONIBLE EN TODO LOS PAISES</b-badge>
                        </div>
                      </template>     

                      <template v-slot:cell(price)="data">
                        <div v-if="data.item.price>0">
                          {{Intl.NumberFormat('es-AR',{style:'currency',currency:'ARS'}).format(data.item.price)}}
                        </div>
                        <div v-else>
                          <div v-if="data.item.legend">
                            {{data.item.legend}}
                          </div>
                          <div v-else>
                            {{Intl.NumberFormat('es-AR',{style:'currency',currency:'ARS'}).format(data.item.price)}}
                          </div>
                        </div>
                      </template>                

                      <template v-slot:cell(f_action)="data">
                        <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                          <b-dropdown-item @click="editTariffConditional(data.item)">
                            <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                          </b-dropdown-item>
                          <b-dropdown-item @click="removeTariffConditional(data.item)">
                            <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                          </b-dropdown-item>
                        </b-dropdown>
                      </template>
                  </b-table>
                  <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
                </b-col>
              </b-row>         
            </b-card-text>
          </b-tab>

        </b-tabs>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>         
        </b-row>
      </b-card>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD LOCALES -->
      <b-modal v-model="modal.form.active"
              header-bg-variant="dark"
              header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <b-row>
          <b-col lg="12">
            <b-form-group label="Nombre">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.form.name"
                            required
                            placeholder="Ingresar un nombre">
              </b-form-input>
            </b-form-group>                                              
          </b-col>   
          <b-col lg="12">
            <b-form-group label="Descripción">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.form.description"
                            required
                            placeholder="Ingresar una descripción">
              </b-form-input>
            </b-form-group>                                              
          </b-col>                                                           
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="save()">Guardar</b-button>          
        </div>
      </b-modal>

      <!-- CRUD TARIFARIO SIMPLE -->
      <b-modal v-model="modal.formTariffSimple.active"
              header-bg-variant="dark"
              header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.formTariffSimple.title}}
        </div>

        <b-row>
          <b-col lg="12">
            <b-form-group label="Nombre">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.formTariffSimple.name"
                            required
                            placeholder="Ingresar un nombre">
              </b-form-input>
            </b-form-group>                                              
          </b-col>          
          <b-col lg="12">
            <b-form-group label="Precio">
              <b-form-input type="number"
                            size="sm"
                            step="0.01"
                            v-model="crud.formTariffSimple.price"
                            required
                            placeholder="Ingresar un precio">
              </b-form-input>
            </b-form-group>                                              
          </b-col>
          <b-col lg="12">
            <b-form-group label="Leyenda" description="Leyenda que se muestra cuando el valor es cero. Sino se carga, no se aplica">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.formTariffSimple.legend"
                            required
                            placeholder="Ingresar una leyenda">
              </b-form-input>
            </b-form-group>                                              
          </b-col>                                                                                 
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.formTariffSimple.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="saveTariffSimple()">Guardar</b-button>          
        </div>
      </b-modal>   

      <!-- CRUD TARIFARIO CONDITIONAL -->
      <b-modal v-model="modal.formTariffConditional.active"
              header-bg-variant="dark"
              header-text-variant="white"
              size="lg">
        <div slot="modal-header">
          {{this.modal.formTariffConditional.title}}
        </div>

        <b-row>
          <b-col lg="6">
            <b-form-group label="Nombre">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.formTariffConditional.name"
                            required
                            placeholder="Ingresar un nombre">
              </b-form-input>
            </b-form-group>                                              
          </b-col>                             
          <b-col md="6">
            <b-form-group label="Destino" description="Destino del envío">
              <b-form-select v-model="crud.formTariffConditional.at_home" :options="arr.destination" size="sm"></b-form-select>
            </b-form-group>          
          </b-col>                                                                              
        </b-row>
        <b-row>  
          <b-col lg="7">
            <b-row>  
              <b-col md="12">
                <b-form-group label="Límites" description="Configuración del limite del envío">
                  <b-form-select v-model="crud.formTariffConditional.province_or_cp" :options="arr.province_or_cp" size="sm"></b-form-select>
                </b-form-group>          
              </b-col>    

              <b-col lg="12" v-if="crud.formTariffConditional.province_or_cp == 'cp'">
                <b-form-group label="Código Postal">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.formTariffConditional.postal_code"
                                required
                                placeholder="Ingresar un código postal">
                  </b-form-input>
                </b-form-group>                                              
              </b-col>  

              <b-col lg="12" v-if="crud.formTariffConditional.province_or_cp == 'province'">
                <div class="container-provinces-delivery-conditional">
                  <b-form-group>
                    <b-form-checkbox-group
                      v-model="crud.formTariffConditional.provinces"
                      :options="arr.provinces"
                      stacked
                    ></b-form-checkbox-group>
                  </b-form-group>
                </div>
              </b-col>
            </b-row>
          </b-col>

          <b-col lg="5">
            <b-row>
              <b-col md="12">
                <b-form-group label="Peso">
                  <b-form-input type="number"
                                size="sm"
                                v-model="crud.formTariffConditional.weight">
                  </b-form-input>
                </b-form-group>
              </b-col>                
              <b-col md="12">
                <b-alert show variant="info" class="alert-provinces-delivery-conditional">
                  Ingrese el peso máximo en KILOS
                </b-alert>
              </b-col>              
              <b-col md="12">
                <hr>
              </b-col>
              <b-col md="4">
                <b-form-group label="Largo">
                  <b-form-input type="number"
                                size="sm"
                                v-model="crud.formTariffConditional.long">
                  </b-form-input>
                </b-form-group>
              </b-col>    
              <b-col md="4">
                <b-form-group label="Alto">
                  <b-form-input type="number"
                                size="sm"
                                v-model="crud.formTariffConditional.height">
                  </b-form-input>
                </b-form-group>
              </b-col> 
              <b-col md="4">
                <b-form-group label="Profundidad">
                  <b-form-input type="number"
                                size="sm"
                                v-model="crud.formTariffConditional.depth">
                  </b-form-input>
                </b-form-group>
              </b-col>                  
              <b-col md="12">
                <b-alert show variant="info" class="alert-provinces-delivery-conditional">
                  Ingrese las medidas máximas en CENTIMETROS
                </b-alert>
              </b-col>            
              <b-col lg="12">
                <b-form-group label="Precio">
                  <b-form-input type="number"
                                size="sm"
                                step="0.01"
                                v-model="crud.formTariffConditional.price">
                  </b-form-input>
                </b-form-group>                                              
              </b-col>                              
            </b-row>              
          </b-col>
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.formTariffConditional.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="saveTariffConditional()">Guardar</b-button>          
        </div>
      </b-modal>            
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import Imagen from '@/components/inc/image/image'

  export default {
    components: {
      Imagen,
    },         
    data: () => {
      return {      
        access: {
          module_id: Modules.CONTENIDOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudStoreDeliveries',
          elements: {}
        }, 
        table : {
          items: [],
          fields: [
            {key: 'id', label: 'ID', sortable: true, class:"align-middle"},
            {key: 'name', label: 'Nombre', class:"align-middle"},            
            {key: 'description', label: 'Descripción', class:"align-middle"},                         
            {key: 'f_action', label:'', class:"align-middle"},
          ],          
        },
        tableTariffSimple : {
          items: [],
          fields: [
            {key: 'id', label: 'ID', sortable: true, class:"align-middle"},
            {key: 'name', label: 'Nombre', class:"align-middle"}, 
            {key: 'country', label: 'País', class:"align-middle"},                                    
            {key: 'price', label: 'Precio', class:"align-middle"},                        
            {key: 'f_action', label:'', class:"align-middle"},
          ],       
        },  
        tableTariffConditional : {
          items: [],
          fields: [
            {key: 'id', label: 'ID', sortable: true, class:"align-middle"},
            {key: 'name', label: 'Nombre', class:"align-middle"}, 
            {key: 'limit', label: 'Límite', class:"align-middle"},
            {key: 'at_home', label: 'Destino', class:"align-middle"}, 
            {key: 'weight', label: 'Peso', class:"align-middle"},                                    
            {key: 'measures', label: 'Medidas', class:"align-middle"},   
            {key: 'country', label: 'País', class:"align-middle"},
            {key: 'price', label: 'Precio', class:"align-middle"},            
            {key: 'f_action', label:'', class:"align-middle"},
          ],       
        },                
        crud: {
          form: {
            id: 0,
            name: '',
            description: '',            
          }, 
          formTariffSimple: {
            id: 0,
            name: '',      
            price: 0,           
            country: 'AR',
            legend: '',
          },    
          formTariffConditional: {
            id: 0,
            name: '',      
            provinces: [],
            weight: 0,
            height: 0,
            long: 0,
            depth: 0,
            price: 0,         
            country: 'AR',  
            postal_code: 0,
            at_home: true,
            province_or_cp: 'province',
          },                             
        },
        arr: {
          provinces: [],
          destination: [
            { text: 'Enviar a Domicilio', value: true },
            { text: 'Enviar a Sucursal', value: false },
          ],
          province_or_cp: [
            { text: 'Provincia', value: 'province' },
            { text: 'Código Postal', value: 'cp' },
          ]          
        },
        modal: {
          form: {
            active: false,
            title: ''
          },
          formTariffSimple: {
            active: false,
            title: ''
          },
          formTariffConditional: {
            active: false,
            title: ''
          },          
        },     
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {
      this.load()
      this.loadTariffSimple()
      this.loadTariffConditional()
    },
    methods: {
      load() {
        var result = serviceAPI.obtenerEntregasLocal()

        result.then((response) => {
          var data = response.data
          this.table.items = data
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
        });        
      },            
      add() {
        this.crud.form.id = 0
        this.crud.form.name = ''
        this.crud.form.description = ''
        
        this.modal.form.title = "Nuevo Local de Entregas"
        this.modal.form.active = true
      },
      edit(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name
        this.crud.form.description = item.description

        this.modal.form.title = "Editar Local de Entregas"
        this.modal.form.active = true
      },
      remove(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name        

        this.$bvModal.msgBoxConfirm('¿Desea borrar el local de entrega (' + this.crud.form.id + ') - '+ this.crud.form.name + '?', {
          title: 'Borrar Local de Entrega',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarEntregasLocal(this.crud.form.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              this.load()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      save() {       
        let loader = this.$loading.show();

        if (this.crud.form.id) {
          var result = serviceAPI.editarEntregasLocal(this.crud.form);
        } else {
          var result = serviceAPI.agregarEntregasLocal(this.crud.form);
        }

        result.then((response) => {
          this.modal.form.active = false
          loader.hide()
          this.load()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },

      loadTariffSimple() {
        var result = serviceAPI.obtenerEntregasTariffSimple()

        result.then((response) => {
          var data = response.data
          this.tableTariffSimple.items = data
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
        });        
      },  
      addTariffSimple() {
        this.crud.formTariffSimple.id = 0
        this.crud.formTariffSimple.name = ''
        this.crud.formTariffSimple.price = 0
        this.crud.formTariffSimple.country = 'AR'
        this.crud.formTariffSimple.legend = ''
        
        this.modal.formTariffSimple.title = "Nueva Tarifa"
        this.modal.formTariffSimple.active = true
      },
      editTariffSimple(item) {
        this.crud.formTariffSimple.id = item.id
        this.crud.formTariffSimple.name = item.name
        this.crud.formTariffSimple.price = item.price
        this.crud.formTariffSimple.country = item.country
        this.crud.formTariffSimple.legend = item.legend

        this.modal.formTariffSimple.title = "Editar Tarifa"
        this.modal.formTariffSimple.active = true
      },
      removeTariffSimple(item) {
        this.crud.formTariffSimple.id = item.id
        this.crud.formTariffSimple.name = item.name        

        this.$bvModal.msgBoxConfirm('¿Desea borrar la tarifa (' + this.crud.formTariffSimple.id + ') - '+ this.crud.formTariffSimple.name + '?', {
          title: 'Borrar Tarifa',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarEntregasTariffSimple(this.crud.formTariffSimple.id);

            result.then((response) => {      
              this.modal.formTariffSimple.active = false        
              loader.hide()
              this.loadTariffSimple()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      saveTariffSimple() {       
        if(this.crud.formTariffSimple.country) {
          if(this.crud.formTariffSimple.country.length!=2) {
            this.$awn.alert('El código Alpha-2 no puede tener mas de 2 caracteres.');
            return false
          }
        }

        let loader = this.$loading.show();

        if (this.crud.formTariffSimple.id) {
          var result = serviceAPI.editarEntregasTariffSimple(this.crud.formTariffSimple);
        } else {
          var result = serviceAPI.agregarEntregasTariffSimple(this.crud.formTariffSimple);
        }

        result.then((response) => {
          this.modal.formTariffSimple.active = false
          loader.hide()
          this.loadTariffSimple()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },


      loadTariffConditional() {
        this.loadProvinces()

        var result = serviceAPI.obtenerEntregasTariffConditional()

        result.then((response) => {
          var data = response.data
          this.tableTariffConditional.items = data          
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
        });        
      },
      addTariffConditional() {
        this.crud.formTariffConditional.id = 0
        this.crud.formTariffConditional.name = ''
        this.crud.formTariffConditional.provinces = []
        this.crud.formTariffConditional.weight = 0
        this.crud.formTariffConditional.height = 0
        this.crud.formTariffConditional.long = 0
        this.crud.formTariffConditional.depth = 0
        this.crud.formTariffConditional.price = 0     
        this.crud.formTariffConditional.country = 'AR'
        this.crud.formTariffConditional.at_home = true
        this.crud.formTariffConditional.province_or_cp = 'province'
        
        this.modal.formTariffConditional.title = "Nueva Tarifa"
        this.modal.formTariffConditional.active = true
      },
      editTariffConditional(item) {
        this.crud.formTariffConditional.id = item.id
        this.crud.formTariffConditional.name = item.name         
        this.crud.formTariffConditional.weight = item.weight
        this.crud.formTariffConditional.height = item.height
        this.crud.formTariffConditional.long = item.long
        this.crud.formTariffConditional.depth = item.depth
        this.crud.formTariffConditional.price = item.price
        this.crud.formTariffConditional.country = item.country
        this.crud.formTariffConditional.province_or_cp = item.province_or_cp
        
        if(item.at_home) {
          this.crud.formTariffConditional.at_home = true
        } else {
          this.crud.formTariffConditional.at_home = false
        }

        if(item.province_or_cp == 'province') {
          this.crud.formTariffConditional.provinces = JSON.parse(item.provinces)
          this.crud.formTariffConditional.postal_code = 0
        } 
        if(item.province_or_cp == 'cp') {
          this.crud.formTariffConditional.provinces = []
          this.crud.formTariffConditional.postal_code = item.postal_code
        }

        this.modal.formTariffConditional.title = "Editar Tarifa"
        this.modal.formTariffConditional.active = true
      },
      removeTariffConditional(item) {
        this.crud.formTariffConditional.id = item.id
        this.crud.formTariffConditional.name = item.name        

        this.$bvModal.msgBoxConfirm('¿Desea borrar la tarifa (' + this.crud.formTariffConditional.id + ') - '+ this.crud.formTariffConditional.name + '?', {
          title: 'Borrar Tarifa',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarEntregasTariffConditional(this.crud.formTariffConditional.id);

            result.then((response) => {      
              this.modal.formTariffConditional.active = false        
              loader.hide()
              this.loadTariffConditional()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      saveTariffConditional() {       
        if(this.crud.formTariffConditional.country) {
          if(this.crud.formTariffConditional.country.length!=2) {
            this.$awn.alert('El código Alpha-2 no puede tener mas de 2 caracteres.');
            return false
          }
        }

        let loader = this.$loading.show();

        if (this.crud.formTariffConditional.id) {
          var result = serviceAPI.editarEntregasTariffConditional(this.crud.formTariffConditional);
        } else {
          var result = serviceAPI.agregarEntregasTariffConditional(this.crud.formTariffConditional);
        }

        result.then((response) => {
          this.modal.formTariffConditional.active = false
          loader.hide()
          this.loadTariffConditional()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
      loadProvinces() {
        var result = serviceAPI.obtenerGenericoProvincias()

        result.then((response) => {
          var data = response.data
          this.arr.provinces = []
          data.forEach(element => {            
            this.arr.provinces.push({ 
              text: element, 
              value: element.normalize("NFD").replace(/[\u0300-\u036f]/g, "") 
            })
          });
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
        });          
      }, 
    }    
  }
</script>
<style>
  .container-provinces-delivery-conditional {
    height: 325px; 
    overflow: auto;
  }
  .alert-provinces-delivery-conditional {
    font-size: 12px;
    padding: 5px;    
  }
</style>